import React, { useState, useEffect, useMemo } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { listUserStates } from '../graphql/queries';
import { createUserState, updateUserState, deleteUserState } from '../graphql/mutations';
import {
  Container, Paper, TextField, Button, Box
} from '@mui/material';

const AdminUserStatePage = () => {
  const [userStates, setUserStates] = useState([]);
  const [newItem, setNewItem] = useState({ username: '', team: '', userStateJSON: '{}' });

  useEffect(() => {
    fetchUserStates();
  }, []);

  const fetchUserStates = async () => {
    try {
      const response = await API.graphql(graphqlOperation(listUserStates));
      setUserStates(response.data.listUserStates.items);
    } catch (error) {
      console.error('Error fetching UserState items:', error);
    }
  };

  const handleAddItem = async () => {
    try {
      await API.graphql(graphqlOperation(createUserState, { input: newItem }));
      setNewItem({ username: '', team: '', userStateJSON: '{}' });
      fetchUserStates();
    } catch (error) {
      console.error('Error adding UserState item:', error);
    }
  };

  const handleUpdateItem = async (id, updatedItem) => {
    try {
      await API.graphql(graphqlOperation(updateUserState, { input: { id, ...updatedItem } }));
      fetchUserStates();
    } catch (error) {
      console.error('Error updating UserState item:', error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await API.graphql(graphqlOperation(deleteUserState, { input: { id } }));
      fetchUserStates();
    } catch (error) {
      console.error('Error deleting UserState item:', error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Team',
        accessor: 'team',
      },
      {
        Header: 'User State JSON',
        accessor: 'userStateJSON',
      },
      {
        Header: 'Action',
        accessor: 'id',
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteItem(row.original.id)}
          >
            Delete
          </Button>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: userStates,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Container maxWidth="lg">
      <style>
        {`
          .shaded-row {
            background-color: #f2f2f2;
          }
        `}
      </style>
      <h2>UserState Admin Page</h2>

      <Paper>
        <Box p={3}>
          <h3>Add New UserState Item</h3>
          <TextField label="Username" value={newItem.username} onChange={(e) => setNewItem({ ...newItem, username: e.target.value })} />
          <TextField label="Team" value={newItem.team} onChange={(e) => setNewItem({ ...newItem, team: e.target.value })} />
          <TextField label="User State JSON" value={newItem.userStateJSON} onChange={(e) => setNewItem({ ...newItem, userStateJSON: e.target.value })} />
          <Button variant="contained" color="primary" onClick={handleAddItem}>Add Item</Button>
        </Box>
      </Paper>

      <Paper>
        <Box p={3}>
          <TextField
            placeholder="Search..."
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={index % 2 ? 'shaded-row' : ''}>
                      {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Paper>

    </Container>
  );
};

export default AdminUserStatePage;