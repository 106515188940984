import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // <-- This is necessary for screen readers (accessibility).

function InfoModal({ notes }) {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <i className="info-icon" onClick={openModal}>ℹ️</i>
      
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Note Info"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px'
          }
        }}
      >
        <h2>Note Information</h2>
        <p>{notes}</p>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
}

export default InfoModal;
