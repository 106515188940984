/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkToolSubmission = /* GraphQL */ `
  query CheckToolSubmission(
    $id: ID!
    $assessedTools: [String]
    $unassessedTools: [String]
    $round: String
    $totaltime: String
    $totalmoney: String
  ) {
    checkToolSubmission(
      id: $id
      assessedTools: $assessedTools
      unassessedTools: $unassessedTools
      round: $round
      totaltime: $totaltime
      totalmoney: $totalmoney
    )
  }
`;
export const generateReport = /* GraphQL */ `
  query GenerateReport($id: ID!) {
    generateReport(id: $id)
  }
`;
export const getSubmissions = /* GraphQL */ `
  query GetSubmissions($id: ID!) {
    getSubmissions(id: $id) {
      id
      assessedTools
      unassessedTools
      round
      user
      totaltime
      totalmoney
      team
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubmissions = /* GraphQL */ `
  query ListSubmissions(
    $filter: ModelSubmissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubmissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assessedTools
        unassessedTools
        round
        user
        totaltime
        totalmoney
        team
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAuthoritativeAnswer = /* GraphQL */ `
  query GetAuthoritativeAnswer($id: ID!) {
    getAuthoritativeAnswer(id: $id) {
      id
      team
      lab
      username
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAuthoritativeAnswers = /* GraphQL */ `
  query ListAuthoritativeAnswers(
    $filter: ModelAuthoritativeAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthoritativeAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        team
        lab
        username
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLab1 = /* GraphQL */ `
  query GetLab1($id: ID!) {
    getLab1(id: $id) {
      id
      phase
      ToolOrActivity
      sortIndex
      isTools
      required
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLab1s = /* GraphQL */ `
  query ListLab1s(
    $filter: ModelLab1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLab1s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phase
        ToolOrActivity
        sortIndex
        isTools
        required
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLab1Answers = /* GraphQL */ `
  query GetLab1Answers($id: ID!) {
    getLab1Answers(id: $id) {
      id
      lab1id
      phase
      team
      ToolOrActivity
      isSelected
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLab1Answers = /* GraphQL */ `
  query ListLab1Answers(
    $filter: ModelLab1AnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLab1Answers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lab1id
        phase
        team
        ToolOrActivity
        isSelected
        notes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserState = /* GraphQL */ `
  query GetUserState($id: ID!) {
    getUserState(id: $id) {
      id
      username
      team
      userStateJSON
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const listUserStates = /* GraphQL */ `
  query ListUserStates(
    $filter: ModelUserStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        team
        userStateJSON
        createdAt
        updatedAt
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userStateByUser = /* GraphQL */ `
  query UserStateByUser(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userStateByUser(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        team
        userStateJSON
        createdAt
        updatedAt
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRound = /* GraphQL */ `
  query GetRound($id: ID!) {
    getRound(id: $id) {
      id
      name
      report
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRounds = /* GraphQL */ `
  query ListRounds(
    $filter: ModelRoundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRounds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        report
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGameState = /* GraphQL */ `
  query GetGameState($id: ID!) {
    getGameState(id: $id) {
      id
      round
      phase
      budget
      timeBudget
      capstoneActive
      lab1Active
      findingsVisible
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGameStates = /* GraphQL */ `
  query ListGameStates(
    $filter: ModelGameStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGameStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        round
        phase
        budget
        timeBudget
        capstoneActive
        lab1Active
        findingsVisible
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTools = /* GraphQL */ `
  query GetTools($id: ID!) {
    getTools(id: $id) {
      id
      title
      cost
      round
      finding
      time
      fix
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTools = /* GraphQL */ `
  query ListTools(
    $filter: ModelToolsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        cost
        round
        finding
        time
        fix
        image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
