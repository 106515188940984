import React, { useState, useEffect, useMemo } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { listLab1s } from '../graphql/queries';
import { createLab1, updateLab1, deleteLab1 } from '../graphql/mutations';
import {
  Container, Paper, TextField, Button, Box
} from '@mui/material';

const AdminLab1Page = () => {
  const [lab1Items, setLab1Items] = useState([]);
  const [newItem, setNewItem] = useState({ phase: '', ToolOrActivity: '', required: false });

  useEffect(() => {
    fetchLab1Items();
  }, []);

  const fetchLab1Items = async () => {
    try {
      const response = await API.graphql(graphqlOperation(listLab1s));
      setLab1Items(response.data.listLab1s.items);
    } catch (error) {
      console.error('Error fetching Lab1 items:', error);
    }
  };

  const handleAddItem = async () => {
    try {
      await API.graphql(graphqlOperation(createLab1, { input: newItem }));
      setNewItem({ phase: '', ToolOrActivity: '', required: false });
      fetchLab1Items();
    } catch (error) {
      console.error('Error adding Lab1 item:', error);
    }
  };

  const handleUpdateItem = async (id, updatedItem) => {
    try {
      await API.graphql(graphqlOperation(updateLab1, { input: { id, ...updatedItem } }));
      fetchLab1Items();
    } catch (error) {
      console.error('Error updating Lab1 item:', error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await API.graphql(graphqlOperation(deleteLab1, { input: { id } }));
      fetchLab1Items();
    } catch (error) {
      console.error('Error deleting Lab1 item:', error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Phase',
        accessor: 'phase',
      },
      {
        Header: 'Tool or Activity',
        accessor: 'ToolOrActivity',
      },
      {
        Header: 'Required',
        accessor: 'required',
        Cell: ({ row }) => (
          <TextField
            type="checkbox"
            checked={row.original.required}
            onChange={(e) => handleUpdateItem(row.original.id, { required: e.target.checked })}
          />
        ),
      },
      {
        Header: 'Action',
        accessor: 'id',
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDeleteItem(row.original.id)}
          >
            Delete
          </Button>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: lab1Items,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Container maxWidth="lg">
      <style>
        {`
          .shaded-row {
            background-color: #f2f2f2;
          }
        `}
      </style>
      <h2>Lab1 Admin Page</h2>

      <Paper>
        <Box p={3}>
          <h3>Add New Lab1 Item</h3>
          <TextField label="Phase" value={newItem.phase} onChange={(e) => setNewItem({ ...newItem, phase: e.target.value })} />
          <TextField label="Tool or Activity" value={newItem.ToolOrActivity} onChange={(e) => setNewItem({ ...newItem, ToolOrActivity: e.target.value })} />
          <TextField label="Required" type="checkbox" checked={newItem.required} onChange={(e) => setNewItem({ ...newItem, required: e.target.checked })} />
          <Button variant="contained" color="primary" onClick={handleAddItem}>Add Item</Button>
        </Box>
      </Paper>

      <Paper>
        <Box p={3}>
          <TextField
            placeholder="Search..."
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={index % 2 ? 'shaded-row' : ''}>
                      {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Paper>

    </Container>
  );
};

export default AdminLab1Page;
