import React, { createContext, useContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Hub } from 'aws-amplify';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({
    username: '',
    userID: '',
    isAdmin: false,
    team: ''
  });

  useEffect(() => {
    const listener = (data) => {
        switch (data.payload.event) {
            case 'signIn':
                //console.log('user signed in'); // Handle the sign-in event
                window.location.reload()
                break;
        }
    };

    Hub.listen('auth', listener);

    return () => Hub.remove('auth', listener); // Cleanup listener
}, []);

  useEffect(() => {
    
    async function fetchUserDetails() {
      try {
        //console.log("fetching currentUserInfo")
        const currentUserInfo = await Auth.currentAuthenticatedUser();

        //console.log("currentUserInfo",currentUserInfo)
    
        const { signInUserSession } = currentUserInfo;
        const { idToken } = signInUserSession;
        const userGroups = idToken.payload['cognito:groups'] || [];
        const userID = idToken.payload.sub;
    
        const isAdmin = userGroups.includes('Admins');    
        const possibleTeams = ['Blue', 'Green', 'Orange', 'Gold', 'Purple'];
        const userTeam = possibleTeams.find(team => userGroups.includes(team)) || '';
    
        setUserDetails(prevDetails => ({
          ...prevDetails,
          username: currentUserInfo.username, 
          userID: userID,
          isAdmin: isAdmin,
          team: userTeam
        }));
      } catch (error) {
        console.error('Error fetching user info:', error);
        setUserDetails(prevDetails => ({
          ...prevDetails,
          username: '',
          userID: '',
          isAdmin: false,
          team: ''
        }));
      }
    }
    
    fetchUserDetails().then(() => setLoading(false));
  }, []);

  return (
    loading ? <div>Loading...</div> : 
    <UserContext.Provider value={{ userDetails, setUserDetails }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};
