import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Typography, Box, Button, Alert, Snackbar, AppBar, Toolbar } from '@mui/material';
import { withAuthenticator } from '@aws-amplify/ui-react';

import AdminPage from './components/AdminPage';
import ProtectedRoute from './components/ProtectedRoute';
import Capstone from './components/Capstone';
import { useNavigate } from 'react-router-dom';
import { useGameContext } from './components/GameContext';

import { useUserContext } from './UserContext';
import TeamCollab from './components/TeamCollab';
import AdminLab1Page from './components/AdminLab1Page';
import UserLab1Page from './components/UserLab1Page';
import Lab1Admin from './components/Lab1Admin';

function App() {
    
    const {
        budget,
        timeBudget,
        remainingBudget,
        remainingTime,
        setCurrentRound, 
        setCurrentPhase,        
        currentRound,
        currentPhase,
        setBudget, 
        setTimeBudget,
        setRemainingBudget,
        setRemainingTime,
        tools,
        capstoneActive,
        lab1Active     
    } = useGameContext();
    
    const { userDetails, setUserDetails } = useUserContext();
    const [auth, setAuth] = useState(true);

    const navigate = useNavigate();

    function OperationsMessage() {
      return (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
              <Typography variant="h6">
                  Head over to{' '}
                  <a href="http://vmouse.ctcubed.com" target="_blank" rel="noopener noreferrer" style={{color: 'blue'}}>
                      vmouse.ctcubed.com
                  </a>{' '}
                  for the flying portion of this capstone.
              </Typography>
          </Box>
      );
  }  

    const handleAdmin = () => {
        navigate('/admin');
    };

    const handleadminLab1Page = () => {
        navigate('/adminLab1Page');
    };

    const handleHome = () => {
        navigate('/');
    };

    const handleCapstone = () => {
        navigate('/capstone');
    };

    const handleLab1 = () => {
        navigate('/lab1');
    };

    const handleLogout = async () => {
        
        try {
            await Auth.signOut();
            window.location.reload();
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };
    
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        Team: {userDetails.team} Round: {currentRound} Phase: {currentPhase}
                    </Typography>
                    {userDetails.username}
                    {auth && (
                        <>
                            <Button color="inherit" onClick={handleHome}>Home</Button>
                            {lab1Active && <Button color="inherit" onClick={handleLab1}>Lab 1</Button>}
                            {capstoneActive && <Button color="inherit" onClick={handleCapstone}>Capstone</Button>}                            
                            {userDetails.isAdmin && <Button color="inherit" onClick={handleAdmin}>Admin</Button>}
                            {/* {userDetails.isAdmin && <Button color="inherit" onClick={handleadminLab1Page}>AdminLab1Page</Button>} */}

                            <Button color="inherit" onClick={handleLogout}>Logout</Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>

            <Routes>
                <Route
                    path="/"
                    element={
                        currentRound &&
                        (currentPhase === 'Capstone' ) ? (
                            <Capstone />
                        ) : currentPhase === 'Operations' ? (
                            <OperationsMessage />
                            ) : currentPhase === 'Lab1-AllPhases' || currentPhase === 'Lab1-PlanOnly'? (
                                <UserLab1Page />
                        ) : currentPhase === 'Capstone-TeamCollab' ? (
                            <TeamCollab />

                            ) : null
                    }
                />
                <Route path="/admin" element={<ProtectedRoute path="/admin" element={<AdminPage />} />} />
                {/* <Route path="/adminLab1Page" element={<ProtectedRoute path="/adminLab1Page" element={<AdminLab1Page />} />} /> */}
                <Route path="/capstone" element={ <Capstone /> } />
                <Route path="/lab1" element={ <UserLab1Page /> } />
                {/* <Route path="/lab1admin" element={ <Lab1Admin /> } /> */}
            </Routes>
            </>        
    );
}

export default withAuthenticator(App);
