import React, { useState, useEffect, useContext } from 'react';
import { API, graphqlOperation, GraphQLResult } from 'aws-amplify'; // Import GraphQLResult
import {
  Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  FormControl, InputLabel, Select, MenuItem, Paper, Container, TextField,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';import { listGameStates, listSubmissions } from '../graphql/queries';
import { updateGameState, deleteSubmissions } from '../graphql/mutations';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useGameContext } from './GameContext';
import AdminLab1Page from './AdminLab1Page';
import AdminUserStatePage from './AdminUserStatePage';


const AdminPage = () => {
  const [gameStates, setGameStates] = useState([]);
  const [updateState, setUpdateState] = useState({ id: '', round: '', phase: '', budget: '', timeBudget: '' });
  const [adminPage, setAdminPage] = useState('');
  const [capstoneSelected, setCapstoneSelected] = useState('False')
  const { lab1Active, setLab1Active, capstoneActive, setCapstoneActive, findingsVisible, setFindingsVisible } = useGameContext();
  const roundOptions = ['1', '2', '3'];
  const phaseOptions = ['Lab1-PlanOnly', 'Lab1-AllPhases','Capstone', 'Capstone-TeamCollab', 'Operations', 'Debrief'];  

  const handleCapstoneToggle = async (event) => {
    setCapstoneActive(event.target.checked);
    if (updateState && updateState.id) {
        try {
            await API.graphql(graphqlOperation(updateGameState, { 
                input: { 
                    id: updateState.id, 
                    capstoneActive: event.target.checked 
                }
            }));
        } catch (error) {
            console.error('Error updating GameState:', error);
        }
    }
};

const handleLab1Toggle = async (event) => {
  setLab1Active(event.target.checked);
  if (updateState && updateState.id) {
      try {
          await API.graphql(graphqlOperation(updateGameState, { 
              input: { 
                  id: updateState.id, 
                  lab1Active: event.target.checked 
              }
          }));
      } catch (error) {
          console.error('Error updating GameState:', error);
      }
  }
};



  const handleFindingsToggle = async (event) => {
    setFindingsVisible(event.target.checked);
    if (updateState && updateState.id) {
        try {
            await API.graphql(graphqlOperation(updateGameState, { 
                input: { 
                    id: updateState.id, 
                    findingsVisible: event.target.checked 
                }
            }));
        } catch (error) {
            console.error('Error updating GameState:', error);
        }
    }
};



  // Fetch game states data on component load
  useEffect(() => {
    fetchGameStates();
    
  }, []);

  useEffect(() => {
    // Set default values for round, phase, budget, and timeBudget based on the first game state (if available)
    if (gameStates.length > 0) {
      const firstGameState = gameStates[0];
      setUpdateState({
        id: firstGameState.id,
        round: firstGameState.round,
        phase: firstGameState.phase,
        budget: firstGameState.budget.toString(), // Initialize budget as a string
        timeBudget: firstGameState.timeBudget.toString(), // Initialize timeBudget as a string
      });
    }
  }, [gameStates]);

  

  const fetchGameStates = async () => {
    try {
      const response = await API.graphql(graphqlOperation(listGameStates));
      const gameStateItems = response.data.listGameStates.items;
      setGameStates(gameStateItems);
      
    } catch (error) {
      console.error('Error fetching game states:', error);
    }
  };


  const updateGameStateItem = async () => {
    try {
      await API.graphql(graphqlOperation(updateGameState, { input: updateState }));
      fetchGameStates();
      setUpdateState({ id: '', round: '', phase: '', budget: '', timeBudget: '' }); 
    } catch (error) {
      console.error('Error updating game state:', error);
    }
  };

    // Render the selected admin page component
    const renderAdminPage = () => {
      switch (adminPage) {
        case 'lab1':
          return <AdminLab1Page />;
        case 'userState':
          return <AdminUserStatePage />;
        // Add cases for other admin pages as needed
        default:
          return null;
      }
    };



  return (
    <Container maxWidth="lg">
      <h2>Admin Page</h2>

      <Box>
      <FormControlLabel
          control={<Switch checked={lab1Active} onChange={handleLab1Toggle} />}
          label="Lab1 Active?"
        />
        <FormControlLabel
          control={<Switch checked={capstoneActive} onChange={handleCapstoneToggle} />}
          label="Capstone Active?"
        />
        <FormControlLabel
          control={<Switch checked={findingsVisible} onChange={handleFindingsToggle} />}
          label="Findings Visible?"
        />
      </Box>

      <h3>Game State</h3>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Phase</TableCell>
                {capstoneSelected && <TableCell>Round</TableCell>}
                {capstoneSelected && <TableCell>Budget</TableCell>}
                {capstoneSelected && <TableCell>Time Budget</TableCell>}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gameStates.map((gameState) => (
                <TableRow key={gameState.id}>

                  <TableCell>
                  <FormControl fullWidth>
                    <Select
                      value={updateState.phase}
                      onChange={(e) => {
                        const newPhase = e.target.value;
                        setUpdateState(prevState => ({ ...prevState, id: gameState.id, phase: newPhase }));
                        if (newPhase.startsWith('Capstone')) {
                          setCapstoneSelected(true);
                        } 
                        else {
                          setCapstoneSelected(false)
                        }
                      }}
                    >
                      {phaseOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </TableCell>
                  {capstoneSelected && (
                    <>
                    <TableCell>
                    <FormControl fullWidth>
                      <Select
                        value={updateState.round}
                        onChange={(e) => setUpdateState({ ...updateState, id: gameState.id, round: e.target.value })}
                      >
                        {roundOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      value={updateState.budget}
                      onChange={(e) => setUpdateState({ ...updateState, budget: e.target.value })}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      value={updateState.timeBudget}
                      onChange={(e) => setUpdateState({ ...updateState, timeBudget: e.target.value })}
                    />
                  </TableCell>
                  </>
                  )}
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={updateGameStateItem}
                      disabled={
                        !updateState.id ||
                        !updateState.round ||
                        !updateState.phase ||
                        !updateState.budget ||
                        !updateState.timeBudget
                      }
                    >
                      Update
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Box sx={{ marginTop: 2 }}>
        <FormControl fullWidth>
          <Select
            value={adminPage}
            onChange={(e) => setAdminPage(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">Select Admin Page</MenuItem>
            <MenuItem value="lab1">Lab 1 Admin Page</MenuItem>
            <MenuItem value="userState">User State Admin Page</MenuItem>
            {/* Add menu items for other admin pages as needed */}
          </Select>
        </FormControl>
      </Box>


      
      {renderAdminPage()}
    </Container>

    
  );
};

export default AdminPage;