import React from 'react';
import {  
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,  
  TextField,  
} from '@mui/material';

  
  const tableContainerStyle = {
    maxWidth: '75%', // Set the maximum width to 75% of the parent container
    margin: '0 auto', // Center-align the table container
  };
  
  function ToolTable({ tools = [], onAssess, onRemove, assessedTools = {}, showFix, showFindings, toolNotes, setToolNotes }) {
      return (
      <Paper style={tableContainerStyle}>
        <TableContainer component="div">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Tool</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Finding</TableCell>
                <TableCell>{showFix ? 'Fix' : 'Action'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tools.filter((tool) => tool && tool.title).map((tool) => (
                <TableRow key={tool.title}>
                  <TableCell>
                    <Avatar variant="rounded" src={`./images/${tool.image}`} alt={tool.title} />
                  </TableCell>
                  <TableCell style={{ backgroundColor: assessedTools[tool.title] ? 'lightgreen' : 'inherit' }}>{tool.title}</TableCell>
                  <TableCell>{tool.cost}</TableCell>
                  <TableCell>{tool.time}</TableCell>
                  <TableCell>
                  <div>
                    {tool.finding}
                    {/* Add the TextField here */}
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      style={{ marginTop: '8px' }} 
                      placeholder="Enter additional details, notes, comments..."
                      value={toolNotes[tool.title] || ''}
                      onChange={(e) => setToolNotes(prev => ({ ...prev, [tool.title]: e.target.value }))}
                    />
                  </div>
                </TableCell>
                    <TableCell>
                    {assessedTools[tool.title] && showFix && showFindings ? (
                      <span style={{ color: 'blue' }}>{tool.fix}</span>
                    ) : assessedTools[tool.title] && showFix ? (
                      <span style={{ color: 'blue' }}>{"Pending instructor release"}</span>
                    ) : assessedTools[tool.title] ? (
                      <Button variant="contained" color="error" onClick={() => onRemove(tool)}>
                        Remove
                      </Button>
                    ) : showFix ? null : (
                      <Button variant="contained" onClick={() => onAssess(tool)}>
                        Fix
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  export default ToolTable