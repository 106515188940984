/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSubmissions = /* GraphQL */ `
  subscription OnCreateSubmissions(
    $filter: ModelSubscriptionSubmissionsFilterInput
  ) {
    onCreateSubmissions(filter: $filter) {
      id
      assessedTools
      unassessedTools
      round
      user
      totaltime
      totalmoney
      team
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSubmissions = /* GraphQL */ `
  subscription OnUpdateSubmissions(
    $filter: ModelSubscriptionSubmissionsFilterInput
  ) {
    onUpdateSubmissions(filter: $filter) {
      id
      assessedTools
      unassessedTools
      round
      user
      totaltime
      totalmoney
      team
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSubmissions = /* GraphQL */ `
  subscription OnDeleteSubmissions(
    $filter: ModelSubscriptionSubmissionsFilterInput
  ) {
    onDeleteSubmissions(filter: $filter) {
      id
      assessedTools
      unassessedTools
      round
      user
      totaltime
      totalmoney
      team
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAuthoritativeAnswer = /* GraphQL */ `
  subscription OnCreateAuthoritativeAnswer(
    $filter: ModelSubscriptionAuthoritativeAnswerFilterInput
  ) {
    onCreateAuthoritativeAnswer(filter: $filter) {
      id
      team
      lab
      username
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAuthoritativeAnswer = /* GraphQL */ `
  subscription OnUpdateAuthoritativeAnswer(
    $filter: ModelSubscriptionAuthoritativeAnswerFilterInput
  ) {
    onUpdateAuthoritativeAnswer(filter: $filter) {
      id
      team
      lab
      username
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAuthoritativeAnswer = /* GraphQL */ `
  subscription OnDeleteAuthoritativeAnswer(
    $filter: ModelSubscriptionAuthoritativeAnswerFilterInput
  ) {
    onDeleteAuthoritativeAnswer(filter: $filter) {
      id
      team
      lab
      username
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLab1 = /* GraphQL */ `
  subscription OnCreateLab1($filter: ModelSubscriptionLab1FilterInput) {
    onCreateLab1(filter: $filter) {
      id
      phase
      ToolOrActivity
      sortIndex
      isTools
      required
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLab1 = /* GraphQL */ `
  subscription OnUpdateLab1($filter: ModelSubscriptionLab1FilterInput) {
    onUpdateLab1(filter: $filter) {
      id
      phase
      ToolOrActivity
      sortIndex
      isTools
      required
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLab1 = /* GraphQL */ `
  subscription OnDeleteLab1($filter: ModelSubscriptionLab1FilterInput) {
    onDeleteLab1(filter: $filter) {
      id
      phase
      ToolOrActivity
      sortIndex
      isTools
      required
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLab1Answers = /* GraphQL */ `
  subscription OnCreateLab1Answers(
    $filter: ModelSubscriptionLab1AnswersFilterInput
  ) {
    onCreateLab1Answers(filter: $filter) {
      id
      lab1id
      phase
      team
      ToolOrActivity
      isSelected
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLab1Answers = /* GraphQL */ `
  subscription OnUpdateLab1Answers(
    $filter: ModelSubscriptionLab1AnswersFilterInput
  ) {
    onUpdateLab1Answers(filter: $filter) {
      id
      lab1id
      phase
      team
      ToolOrActivity
      isSelected
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLab1Answers = /* GraphQL */ `
  subscription OnDeleteLab1Answers(
    $filter: ModelSubscriptionLab1AnswersFilterInput
  ) {
    onDeleteLab1Answers(filter: $filter) {
      id
      lab1id
      phase
      team
      ToolOrActivity
      isSelected
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserState = /* GraphQL */ `
  subscription OnCreateUserState(
    $filter: ModelSubscriptionUserStateFilterInput
    $userId: String
  ) {
    onCreateUserState(filter: $filter, userId: $userId) {
      id
      username
      team
      userStateJSON
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const onUpdateUserState = /* GraphQL */ `
  subscription OnUpdateUserState(
    $filter: ModelSubscriptionUserStateFilterInput
    $userId: String
  ) {
    onUpdateUserState(filter: $filter, userId: $userId) {
      id
      username
      team
      userStateJSON
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const onDeleteUserState = /* GraphQL */ `
  subscription OnDeleteUserState(
    $filter: ModelSubscriptionUserStateFilterInput
    $userId: String
  ) {
    onDeleteUserState(filter: $filter, userId: $userId) {
      id
      username
      team
      userStateJSON
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const onCreateRound = /* GraphQL */ `
  subscription OnCreateRound($filter: ModelSubscriptionRoundFilterInput) {
    onCreateRound(filter: $filter) {
      id
      name
      report
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRound = /* GraphQL */ `
  subscription OnUpdateRound($filter: ModelSubscriptionRoundFilterInput) {
    onUpdateRound(filter: $filter) {
      id
      name
      report
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRound = /* GraphQL */ `
  subscription OnDeleteRound($filter: ModelSubscriptionRoundFilterInput) {
    onDeleteRound(filter: $filter) {
      id
      name
      report
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGameState = /* GraphQL */ `
  subscription OnCreateGameState(
    $filter: ModelSubscriptionGameStateFilterInput
  ) {
    onCreateGameState(filter: $filter) {
      id
      round
      phase
      budget
      timeBudget
      capstoneActive
      lab1Active
      findingsVisible
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGameState = /* GraphQL */ `
  subscription OnUpdateGameState(
    $filter: ModelSubscriptionGameStateFilterInput
  ) {
    onUpdateGameState(filter: $filter) {
      id
      round
      phase
      budget
      timeBudget
      capstoneActive
      lab1Active
      findingsVisible
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGameState = /* GraphQL */ `
  subscription OnDeleteGameState(
    $filter: ModelSubscriptionGameStateFilterInput
  ) {
    onDeleteGameState(filter: $filter) {
      id
      round
      phase
      budget
      timeBudget
      capstoneActive
      lab1Active
      findingsVisible
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTools = /* GraphQL */ `
  subscription OnCreateTools($filter: ModelSubscriptionToolsFilterInput) {
    onCreateTools(filter: $filter) {
      id
      title
      cost
      round
      finding
      time
      fix
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTools = /* GraphQL */ `
  subscription OnUpdateTools($filter: ModelSubscriptionToolsFilterInput) {
    onUpdateTools(filter: $filter) {
      id
      title
      cost
      round
      finding
      time
      fix
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTools = /* GraphQL */ `
  subscription OnDeleteTools($filter: ModelSubscriptionToolsFilterInput) {
    onDeleteTools(filter: $filter) {
      id
      title
      cost
      round
      finding
      time
      fix
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
