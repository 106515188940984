import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip'; // Importing Tooltip
import { API, graphqlOperation } from 'aws-amplify';
import { listUserStates } from '../graphql/queries';
import { useGameContext } from './GameContext';
import { useUserContext } from '../UserContext';
import '../TeamCollab.css'
import InfoModal from './InfoModal';
import { Avatar, Select, MenuItem } from '@mui/material';

const TeamCollab = () => {
  const [teamData, setTeamData] = useState([]);
  const [parsedTeamData, setParsedTeamData] = useState({});
  const [userDataSummary, setUserDataSummary] = useState({}); 
  const [loading, setLoading] = useState(true);
  const { tools } = useGameContext();
  const { userDetails } = useUserContext();
  const [allTeams, setAllTeams] = useState([]); // Array of all teams
  const [selectedTeam, setSelectedTeam] = useState(""); // Selected team


  useEffect(() => {
    if (userDetails.isAdmin) {
      fetchAllTeams();
    }
  }, [userDetails.isAdmin]);

  const fetchAllTeams = async () => {
    try {
      const response = await API.graphql(graphqlOperation(listUserStates));
      const userStates = response.data.listUserStates.items;
  
      // Extract unique team names
      const teams = Array.from(new Set(userStates.map(userState => userState.team)));
  
      setAllTeams(teams);
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };
  


  useEffect(() => {
    fetchTeamData();
  }, []);

  const fetchTeamData = async (teamName) => {
    try {
      
      const teamToFilter = teamName ? teamName : userDetails.team;
      const userData = await API.graphql(graphqlOperation(listUserStates, { filter: { team: { eq: teamToFilter } } }));
      const teamData = userData.data.listUserStates.items;
      setTeamData(teamData);

      parseTeamData(teamData); 
    } catch (error) {
      console.error('Error fetching team data:', error);
    }
    setLoading(false);
  };

  // Function to transform tools data to include notes
  const transformToolsData = (toolsData, toolNotes) => {
    const transformed = {};
    for (const [toolName, toolValue] of Object.entries(toolsData)) {
      transformed[toolName] = toolNotes && toolNotes[toolName] ? toolNotes[toolName] : null;
    }
    return transformed;
};

const AdminPanel = () => {
  if (!userDetails.isAdmin) return null;

  const handleTeamChange = (event) => {
    setSelectedTeam(event.target.value);
    // Fetch and display the selected team's info
    fetchTeamData(event.target.value);
  };

  return (
    <div>
      <Select
        labelId="team-select-label"
        id="team-select"
        value={selectedTeam}
        onChange={handleTeamChange}
      >
        {allTeams.map((team) => (
          <MenuItem key={team} value={team}>{team}</MenuItem>
        ))}
      </Select>
    </div>
  );
};


  

const parseTeamData = (data) => {
  const parsedData = {};
  const summaryData = {}; 
  data.forEach(user => {
    try {
      const userState = JSON.parse(user.userStateJSON);
      parsedData[user.id] = userState;

      // Populate summaryData with the user's data
      summaryData[user.username] = {
        assessedTools: userState.assessedTools ? transformToolsData(userState.assessedTools, userState.toolNotes) : {},
        unassessedTools: userState.unassessedTools ? transformToolsData(userState.unassessedTools, userState.toolNotes) : {},
      };
    } catch (error) {
      console.error('Error parsing userStateJSON:', error);
    }
  });
  setParsedTeamData(parsedData);
  setUserDataSummary(summaryData); 

};

const getUserAggregateData = () => {
  const aggregateData = {};
  teamData.forEach(user => {
      const username = user.username;
      const assessedTools = userDataSummary[username]?.assessedTools || {};
      const selectedToolNames = Object.keys(assessedTools); 

      // Calculate total cost and time for each user
      let totalCost = 0;
      let totalTime = 0;
      selectedToolNames.forEach(toolName => {
          const tool = tools.find(t => t.title.trim() === toolName.trim());
          if (tool) {
              totalCost += parseInt(tool.cost) || 0;
              totalTime += tool.time || 0;
          }
      });

      aggregateData[username] = {
          selectedTools: selectedToolNames,
          totalCost,
          totalTime,
      };
  });
  return aggregateData;
};

const renderUserAggregateTable = () => {
  const aggregateData = getUserAggregateData();

  return (
      <table>
          <thead>
              <tr>
                  <th>Username</th>
                  <th>Selected Tools</th>
                  <th>Total Cost</th>
                  <th>Total Time</th>
              </tr>
          </thead>
          <tbody>
              {teamData.map(user => (
                  <tr key={user.id}>
                      <td>{user.username}</td>
                      <td>
                          {aggregateData[user.username].selectedTools.map(tool => (
                              <div key={tool}>{tool}</div>
                          ))}
                      </td>
                      <td>{aggregateData[user.username].totalCost}</td>
                      <td>{aggregateData[user.username].totalTime}</td>
                  </tr>
              ))}
          </tbody>
      </table>
  );
};

const renderToolsUsersTable = () => {
  return (
    <table>
      <thead>
        <tr>
          
          <th>Tool</th>
          <th>Cost</th>   
          <th>Time</th>   
          {teamData.map(user => (
            <th key={user.id}>{user.username}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tools.map(tool => (
          <tr key={tool.id}>
            
            
            
            <td><Avatar variant="rounded" src={`./images/${tool.image}`} alt={tool.title} />{tool.title}</td>
            <td>{tool.cost}</td>   
            <td>{tool.time}</td>   
            {teamData.map(user => {
              const username = user.username;
              const assessed = userDataSummary[username]?.assessedTools?.[tool.title.trim()];
              const unassessed = userDataSummary[username]?.unassessedTools?.[tool.title.trim()];
              const notes = assessed || unassessed; // Changed to check if the tool was either assessed or unassessed

              return (
                <td key={`${tool.id}-${user.id}`}>
                  <div className="icon-container">
                    {typeof assessed !== 'undefined' ? (
                      <span>✅</span>
                    ) : typeof unassessed !== 'undefined' ? <span>❌</span> : ''}
                    {notes && (
                      <InfoModal notes={notes} />
                    )}
                  </div>
                </td>
              );
            })}

          </tr>
        ))}
      </tbody>
    </table>
  );
};

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="team-collab-container">
      <AdminPanel />
      {renderUserAggregateTable()} 
      {renderToolsUsersTable()}
      {/* <div>
        <h2>User Data Summary:</h2>
        <pre>{JSON.stringify(userDataSummary, null, 2)}</pre>
      </div> */}
      
      
    </div>
  );
}

export default TeamCollab;
