// fix subscription not updating properly
// make sure select button submits an answer

import React, { useState, useEffect, useReducer } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listLab1s, listLab1Answers } from '../graphql/queries';
import { useUserContext } from '../UserContext';
import { useGameContext } from './GameContext';
import { updateLab1, updateLab1Answers, createLab1Answers } from '../graphql/mutations';
import { onUpdateLab1Answers, onCreateLab1Answers } from '../graphql/subscriptions';
import { 
  Container, Drawer, Paper, Box, Typography, Button, Grid, Checkbox, Dialog, 
  DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Switch, FormControlLabel, 
  Select, MenuItem 
} from '@mui/material';

const UserLab1Page = () => {
  const [lab1Items, setLab1Items] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [notes, setNotes] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);  
  const [userTeam, setUserTeam] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [selectedPhase, setSelectedPhase] = useState("");
  const [selectedToolOrActivity, setSelectedToolOrActivity] = useState("");
  const { userDetails } = useUserContext();  
  const [showAdminItems, setShowAdminItems] = useState(false);
  const [teams, setTeams] = useState([]); 

  const {    
    currentPhase,    
  } = useGameContext();

  useEffect(() => {
    let unsubscribe; // Define an unsubscribe variable
  
    async function initialize() {
      //console.log('Initializing...'); // Log when initialization starts
      await retrieveUserDetails(); // This needs to finish to have userTeam set
      //console.log('User details retrieved, userTeam:', userTeam); // Log after userDetails retrieval
      await fetchLab1Items();
      //console.log('Lab1 items fetched'); // Log after items are fetched
      const unsubscribeFromUpdates = subscribeToAnswers(); // Existing subscription
      const unsubscribeFromCreates = subscribeToCreateAnswers(); // New subscription for creates

      unsubscribe = () => {
        unsubscribeFromUpdates();
        unsubscribeFromCreates();
      };
    
    }
    
  
    initialize();
  
    // Return a cleanup function from the useEffect hook
    return () => {
      if (unsubscribe) {
        //console.log('Unsubscribing from updates and creates...'); // Log when trying to unsubscribe
        unsubscribe(); // Unsubscribe when the component unmounts
      }
    };
  }, [userTeam]);

  useEffect(() => {
    
    //console.log('lab1Items has changed:', lab1Items);
  
    
  
  }, [lab1Items]); // Dependency array with lab1Items, this effect runs every time lab1Items changes.
  

  useEffect(() => {
    if (userTeam) {
      fetchLab1Items();
    }
  }, [userTeam]); 

  const fetchTeams = async () => {
    const possibleTeams = ['Blue', 'Green', 'Orange', 'Gold', 'Purple'];
    setTeams(possibleTeams)
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const handleAdminToggleChange = (event) => {
    setShowAdminItems(event.target.checked);
  };

  // Handle team selection
  const handleTeamChange = (event) => {
    setUserTeam(event.target.value);
  };

  const AdminPanel = () => {

    if (!isAdmin) return null;

    return (

    <Container>

        <FormControlLabel
          control={
            <Switch checked={showAdminItems} onChange={handleAdminToggleChange} />
          }
          label="Show Admin Items"
        />
        <Select
          labelId="team-select-label"
          id="team-select"
          value={userTeam}
          label="Team"
          onChange={handleTeamChange}
          fullWidth
        >
          {teams.map((team) => (
            <MenuItem key={team} value={team}>{team}</MenuItem>
          ))}
        </Select>
      </Container>
    
    );
  };

  const subscribeToAnswers = () => {
    //console.log('Subscribing to answers...'); // Log when subscription starts
    const subscription = API.graphql(graphqlOperation(onUpdateLab1Answers)).subscribe({
      next: (response) => {
        const updatedAnswer = response.value.data.onUpdateLab1Answers;
        // Assuming 'lab1ItemId' is the field in the updated answer that corresponds to the item ID
        //console.log('(Updated answer for team, current user team)', updatedAnswer.team, userTeam);
        if (updatedAnswer.team === userTeam) {
          //console.log('Updating UI with new answer:', updatedAnswer);
          updateItemSelection(updatedAnswer);
        }
      },
      error: error => {
        console.error('Subscription error:', error);
      }
    });
    
    return () => subscription.unsubscribe();
  };

  const subscribeToCreateAnswers = () => {
    //console.log('Subscribing to new answers...'); // Log when subscription starts
    const subscription = API.graphql(graphqlOperation(onCreateLab1Answers)).subscribe({
      next: (response) => {
        //console.log('New subscription response received:', response); // Log the response received
        const newAnswer = response.value.data.onCreateLab1Answers;
  
        if (newAnswer.team === userTeam) {
          //console.log('New answer received:', newAnswer); // Log the actual answer received
          // You may want to implement a function to handle the new answer
          updateItemSelection(newAnswer);
        }
      },
      error: error => {
        console.error('New subscription error:', error); // Log any subscription error
      }
    });
    return () => subscription.unsubscribe();
  };
  
  const updateItemSelection = (updatedAnswer) => {
    // Log the updated answer to verify the data structure
    //console.log("Updating item selection with answer", updatedAnswer);
  
    // Use functional state update to ensure we have the latest state
    setLab1Items((prevItems) => {
      return prevItems.map((item) => {
        if (item.id === updatedAnswer.lab1ItemId) {
          // Found the item that needs to be updated
          return {
            ...item,
            selected: updatedAnswer.isSelected,
            userNotes: updatedAnswer.notes,
          };
        }
        return item; // Return all other items unchanged
      });
    });
  };

  const retrieveUserDetails = () => {
    try {
      setIsAdmin(userDetails.isAdmin);
      if(!isAdmin){
        setUserTeam(userDetails.team);
      }
       // This operation is synchronous if userDetails is already available
      //console.log('User details set:', userDetails); // Log the userDetails being set
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };
  
  

  const fetchLab1Items = async () => {
    if (!userTeam) return;
  
    try {
      let items = [];
      let nextToken;
  
      // Pagination loop to fetch all Lab1 items
      do {
        const { data } = await API.graphql(graphqlOperation(listLab1s, {
          nextToken: nextToken,
          // Add any other necessary parameters for your query
        }));
        const newItems = data.listLab1s.items.map(item => ({
          ...item,
          selected: false,
          userNotes: "",
        }));
        items = [...items, ...newItems];
        nextToken = data.listLab1s.nextToken;
      } while (nextToken);
  
      // Sort items by sortIndex
      items.sort((a, b) => a.sortIndex - b.sortIndex);
      
      // Pagination loop to fetch all Lab1 answers
      let answers = [];
      nextToken = null;
      do {
        const answersResponse = await API.graphql(
          graphqlOperation(listLab1Answers, { 
            filter: { team: { eq: userTeam } },
            nextToken: nextToken 
          })
        );
        answers = [...answers, ...answersResponse.data.listLab1Answers.items];
        nextToken = answersResponse.data.listLab1Answers.nextToken;
      } while (nextToken);
  
      // Update items based on answers matched by ToolOrActivity
      answers.forEach((answer) => {
        const matchedItems = items.filter((item) => item.ToolOrActivity === answer.ToolOrActivity);
        matchedItems.forEach((matchedItem) => {
          matchedItem.selected = answer.isSelected ? answer.isSelected : false;
          matchedItem.userNotes = answer.notes;
        });
      });
  
      setLab1Items(items); // Update state with all items
    } catch (error) {
      console.error('Error fetching Lab1 items:', error);
    }
  };
  
  
  const handleSelectItem = async (id) => {
    // Find the item that was selected/unselected
    const selectedItem = lab1Items.find(item => item.id === id);
    
    if (selectedItem) {
      // Toggle the 'selected' state
      const updatedSelectedState = !selectedItem.selected;
  
      // Update the local state to reflect the change
      setLab1Items(prevItems =>
        prevItems.map(item =>
          item.id === id ? { ...item, selected: updatedSelectedState } : item
        )
      );
  
      // Prepare the answer data for the API call
      const answerData = {
        id: selectedItem.id + userTeam, // Assuming the ID is a combination of item ID and team
        team: userTeam,
        notes: selectedItem.userNotes, // Preserve the existing notes
        phase: selectedItem.phase,
        isSelected: updatedSelectedState,
        ToolOrActivity: selectedItem.ToolOrActivity,
      };
  
      try {
        // Check if an answer already exists for this item and team
        // console.log("checking existing answer", selectedItem.phase, userTeam, selectedItem.ToolOrActivity)
        const existingAnswer = await fetchExistingAnswer(selectedItem.phase, userTeam, selectedItem.ToolOrActivity);
        // console.log("existing answer check complete:", existingAnswer)

        if (existingAnswer) {
          // Update existing answer
          // console.log("updating existing answer")
          const input = { ...answerData, id: existingAnswer.id };
          await API.graphql(graphqlOperation(updateLab1Answers, { input }));
        } else {
          // Create new answer if it doesn't exist
          // console.log("creating a new answer")
          const input = { ...answerData };
          await API.graphql(graphqlOperation(createLab1Answers, { input }));
        }
        
        //console.log('Saved selection state for:', id);
      } catch (error) {
        console.error('Error updating selection state:', error);
      }
    }
  };
  

  const handleOpenDialog = (id) => {

    //console.log("button clicked ", id)
    const selectedItem = lab1Items.find(item => item.id === id);
    //console.log("selectedItem",selectedItem)
    if (selectedItem) {
      setSelectedItemId(id);
      setNotes(selectedItem.userNotes);
      setSelectedPhase(selectedItem.phase);
      setSelectedToolOrActivity(selectedItem.ToolOrActivity);
      setOpen(true);
    }
  };

  const handleCloseDialog = (save) => {
    if (save && selectedItemId) {
      saveLab1Answer();
    } else {
      setOpen(false);
    }
  };

  const fetchExistingAnswer = async (phase, team, toolOrActivity) => {
    try {
      let nextToken = null;
      let existingAnswer = null;
  
      do {
        const response = await API.graphql(graphqlOperation(listLab1Answers, {
          filter: {
            phase: { eq: phase },
            team: { eq: team },
            ToolOrActivity: { eq: toolOrActivity }
          },
          nextToken: nextToken
        }));
  
        const answers = response.data.listLab1Answers.items;
        existingAnswer = answers.find(answer => answer.ToolOrActivity === toolOrActivity && answer.phase === phase && answer.team === team);
        nextToken = response.data.listLab1Answers.nextToken;
  
      } while (!existingAnswer && nextToken);
  
      return existingAnswer;
  
    } catch (error) {
      console.error("Error fetching existing answer:", error);
      return null;
    }
  };
  
  
  const saveLab1Answer = async () => {
    const selectedItem = lab1Items.find(item => item.id === selectedItemId);
    if (selectedItem) {
      setSelectedPhase(selectedItem.phase);
      setSelectedToolOrActivity(selectedItem.ToolOrActivity);

      const answerData = {
        id: selectedItem.id + userTeam,
        team: userTeam,
        notes: notes,
        phase: selectedPhase,
        isSelected: selectedItem.selected,
        ToolOrActivity: selectedToolOrActivity,
      };

  
    try {
      // Fetch existing answer based on phase, team, and ToolOrActivity
      // (Assuming you have a function like `fetchExistingAnswer` that does this)
      
      const existingAnswer = await fetchExistingAnswer(selectedPhase, userTeam, selectedToolOrActivity);
  
      if (existingAnswer) {
        // Update existing answer
        const input = { ...answerData, id: existingAnswer.id };
        await API.graphql(graphqlOperation(updateLab1Answers, { input }));
      } else {
        // Create new answer
        const input = { ...answerData };
        //console.log('Creating Lab1 Answer with data:', answerData);
        await API.graphql(graphqlOperation(createLab1Answers, { input }));
      }
  
      // Close dialog and refresh items
      setOpen(false);
      fetchLab1Items();
  
    } catch (error) {
      console.error('Error saving Lab1 answer:', error);
      //console.log(JSON.stringify(error, null, 2)); // To print the error in a more readable format
    }
    
  }
  };
  
  
  

  // Organize items by phase
  const itemsByPhase = lab1Items.reduce((acc, item) => {
    acc[item.phase] = acc[item.phase] || [];
    acc[item.phase].push(item);
    return acc;
  }, {});

  Object.keys(itemsByPhase).forEach(phase => {
    itemsByPhase[phase].sort((a, b) => a.sortIndex - b.sortIndex);
  });



  const phasesOrder = (currentPhase === 'Lab1-PlanOnly' ? ["Plan"] : ["Plan", "Develop", "Build", "Test", "Release & Deliver", "Deploy", "Operate", "Monitoring"]);
  


  const handleEditItem = (item) => {
    setEditItem(item);
    setEditOpen(true);
  };

  const handleCloseEditDialog = async (save) => {
    if (save && editItem) {
      try {
        const input = {
          id: editItem.id,
          ToolOrActivity: editItem.ToolOrActivity,
          required: editItem.required,
          isTools: editItem.isTools,
          sortIndex: editItem.sortIndex // Ensure the updated sortIndex is sent
        };
        await API.graphql(graphqlOperation(updateLab1, { input }));
        fetchLab1Items(); // Refresh the items list
      } catch (error) {
        console.error('Error updating item:', error);
      }
    }
    setEditOpen(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditItem(prevState => ({
      ...prevState,
      [name]: name === 'sortIndex' ? parseInt(value, 10) : value
    }));
  };

  const renderCheckboxAndButton = (item) => (
    <Box key={item.id} display="flex" alignItems="center">
      <Checkbox
        checked={item.selected}
        onChange={() => handleSelectItem(item.id)}
        color="primary"
      />
      <Button
        variant="contained"
        onClick={() => handleOpenDialog(item.id)}
        style={{
          flexGrow: 1,
          // If item is selected, make it green, otherwise check if there are notes, if so, make it red, otherwise gray.
          backgroundColor: item.selected ? "green" : item.userNotes ? "red" : item.required ? "blue" : "gray",
          whiteSpace: 'normal',
          lineHeight: '1.2'
        }}
      >
        {item.ToolOrActivity}
      </Button>
      {showAdminItems && isAdmin && (
        <Button
          variant="outlined"
          onClick={() => handleEditItem(item)}
          style={{ marginLeft: '10px' }}
        >
          Edit
        </Button>
      )}
    </Box>
  );
  

  const renderAdminEditDialog = () => (
    <Dialog open={editOpen} onClose={() => handleCloseEditDialog(false)}>
      <DialogTitle>Edit Item</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="ToolOrActivity"
          label="Title"
          type="text"
          fullWidth
          variant="outlined"
          value={editItem?.ToolOrActivity || ''}
          onChange={handleEditChange}
        />
        <TextField
          margin="dense"
          name="sortIndex"
          label="Sort Index"
          type="number"
          fullWidth
          variant="outlined"
          value={editItem?.sortIndex || ''}
          onChange={handleEditChange}
        />
          <FormControlLabel
            control={
              <Checkbox
                checked={editItem?.required || false}
                onChange={(e) => setEditItem({ ...editItem, required: e.target.checked })}
                name="required"
                color="primary"
              />
            }
            label="Required"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editItem?.isTools || false}
                onChange={(e) => setEditItem({ ...editItem, isTools: e.target.checked })}
                name="isTools"
                color="primary"
              />
            }
            label="Is Tools"
          />
                </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseEditDialog(false)}>Cancel</Button>
        <Button onClick={() => handleCloseEditDialog(true)} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderUserEditDialog = () => (

    <Dialog open={open} onClose={() => handleCloseDialog(false)}>
    <DialogTitle>Add Notes</DialogTitle>
    <DialogContent>
      <DialogContentText>
        You can add some notes for this item.
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="notes"
        label="Notes"
        type="text"
        fullWidth
        variant="outlined"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleCloseDialog(false)}>Cancel</Button>
      <Button onClick={() => handleCloseDialog(true)} color="primary">
        Save
      </Button>
    </DialogActions>
  </Dialog>


  )
  



  return (
    
    <Container maxWidth="lg"  sx={{ flexGrow: 1 }}>
      
      
      <Paper>
      
        <Box p={3}>
          <AdminPanel />
          <Grid container spacing={2}>
            {phasesOrder.map((phase, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={phase} style={{ borderRight: index < phasesOrder.length - 1 ? '1px solid #ccc' : '' }}>
                <Typography variant="h6" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.2rem', marginBottom: '10px' }}>{phase}</Typography>
                
                {/* Tools Subsection */}
                <Typography variant="subtitle1" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1rem', color: '#4CAF50', marginBottom: '5px' }}>Tools</Typography>
                <Box display="flex" flexDirection="column" gap="10px" paddingRight="15px">
                  {itemsByPhase[phase]?.filter(item => item.isTools).map(renderCheckboxAndButton)}
                </Box>

                {/* Activity Subsection */}
                <Typography variant="subtitle1" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1rem', color: '#FF9800', marginBottom: '5px' }}>Activities</Typography>
                <Box display="flex" flexDirection="column" gap="10px" paddingRight="15px">
                  {itemsByPhase[phase]?.filter(item => !item.isTools).map(renderCheckboxAndButton)}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>

      {renderAdminEditDialog()}
      {renderUserEditDialog()}
      
    </Container>
    
  );
};

export default UserLab1Page;
