import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {  
  Grid,
  Typography,
  Paper,
  Button,  
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Modal,  
  LinearProgress,
  Select,
  MenuItem,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useGameContext } from './GameContext';
import { API, graphqlOperation } from 'aws-amplify';
import { listUserStates, getUserState } from '../graphql/queries';
import ToolTable from './ToolTable'
import { createUserState, updateUserState } from '../graphql/mutations';
import { useUserContext } from '../UserContext';
import { v4 as uuidv4 } from 'uuid';
import { createSubmissions } from '../graphql/mutations';



function ProgressBar({ label, value, max }) {
    
  const progress = (value / max) * 100;
  const isExceeded = value > max;

  return (
    <div style={{ marginBottom: '12px' }}>
      <Typography variant="h6">{`${label}: ${value} of ${max}`}</Typography>
      <LinearProgress 
        variant="determinate" 
        value={Math.min(progress, 100)} 
        style={{
          backgroundColor: isExceeded ? 'red' : 'rgba(0, 0, 0, 0.1)', // Background color when exceeded
        }} 
        color={isExceeded ? 'secondary' : 'primary'} 
      />
    </div>
  );
}




function Capstone() {
  const [assessedTools, setAssessedTools] = useState({});
  const [unassessedTools, setUnassessedTools] = useState({});
  const [showFixColumn, setShowFixColumn] = useState(false);
  const [leftColumn, setLeftColumn] = useState([]);
  const [rightColumn, setRightColumn] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toolNotes, setToolNotes] = useState({});
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [resetChoice, setResetChoice] = useState(null);
  const [allUsers, setAllUsers] = useState([]); // Array of all user objects
  const [selectedUserId, setSelectedUserId] = useState(""); // Selected user ID
  const [attackReport, setAttackReport] = useState(null);


  const openConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };
  


  const { userDetails, setUserDetails } = useUserContext();

  const {
    budget,
    timeBudget,
    remainingBudget,
    setRemainingBudget,
    remainingTime,
    setRemainingTime,
    currentRound,
    currentPhase,
    tools,
    findingsVisible
  } = useGameContext();

  const formatUserState = () => {

    const totalCost = tools.filter((tool) => assessedTools[tool.title]).reduce((acc, tool) => acc + parseFloat(tool.cost), 0);
    const totalTime = tools.filter((tool) => assessedTools[tool.title]).reduce((acc, tool) => acc + parseFloat(tool.time), 0);

    return JSON.stringify({
        assessedTools: assessedTools,
        unassessedTools: unassessedTools,
        toolNotes: toolNotes,
        showFixColumn: showFixColumn,
        team: userDetails.team,
        cost: totalCost,
        timecost: totalTime
    });
};

const checkAttackScenarios = (userTools) => {


  const report = {
    attack1Success: userTools.includes('Nexus (Repository)'), 
    attack2Success: userTools.includes('SonarQube (Static Analysis)') || (userTools.includes('Twistlock (Container Vuln Scanner)') && userTools.includes('Snyk (Source Code Security Plugin)') && userTools.includes('Snyk (Software Compositoin Analysis)') && userTools.includes('Mayhem (Dynamic Analysis)')), 
    attack3Success: userTools.includes('GitLab (Source Code Management)') || (userTools.includes('Snyk (Source Code Security Plugin)')),      
    attack4Success: userTools.includes('DCAR base images (Trusted Container Images)') || (userTools.includes('Twistlock (Container Vuln Scanner)')), 
    attack5Success: userTools.includes('VS Code (IDE) and dev workstation') || ( 
                                                                                  (userTools.includes('Anti-virus and Firewall (Host-based Security)')) ||
                                                                                  (userTools.includes('SolarWinds (CMDB)') && userTools.includes('Splunk (Logging, Analysis, Auditing)')) ||
                                                                                  (userTools.includes('GitLab (Source Code Management)') && userTools.includes('Nexus (Repository)') && userTools.includes('Azure DevOps (CI/CD Orchestrator)') )
                                                                                  ), 




                                                                           };



                                                                            
    
  return report;

    
};

const AdminPanel = () => {
  if (!userDetails.isAdmin) return null;

  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
    // Fetch and display the selected user's info
    fetchUserState(event.target.value);
  };

  const handleCheckAnswer = async () => {
    if (!selectedUserId) return; // Ensure a user is selected

    try {
      const response = await API.graphql(graphqlOperation(getUserState, { id: selectedUserId }));
      if (response.data && response.data.getUserState) {
        const userState = JSON.parse(response.data.getUserState.userStateJSON);
        const userTools = Object.keys(userState.assessedTools);
        const report = checkAttackScenarios(userTools);
        setAttackReport(report);
      }
    } catch (error) {
      console.error('Error fetching user state for report:', error);
    }
  };

  return (
    <div>
      <Select
        labelId="user-select-label"
        id="user-select"
        value={selectedUserId}
        label="Select User"
        onChange={handleUserChange}
      >
        {allUsers.map((user) => (
          <MenuItem key={user.id} value={user.id}>{user.username}</MenuItem>
        ))}
      </Select>

      {/* Check Answer Button */}
      {userDetails.isAdmin && (
        <Button variant="contained" color="primary" onClick={handleCheckAnswer}>
          Check Answer
        </Button>
      )}

{attackReport && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Attack Scenario</TableCell>
              <TableCell>Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Attack 1 Nexus Repository</TableCell>
              <TableCell>{attackReport.attack1Success ? "This attack was defeated" : "This attack was successful" }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Attack 2 SonarQube-Static Analysis or (Twistlock + Snyk SCA and Plugin + Mayhem)</TableCell>
              <TableCell>{attackReport.attack2Success ? "This attack was defeated" : "This attack was successful" }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Attack 3 Gitlab or Snyk Plugin</TableCell>
              <TableCell>{attackReport.attack3Success ? "This attack was defeated" : "This attack was successful" }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Attack 4 DCAR Base Images or Twistlock</TableCell>
              <TableCell>{attackReport.attack4Success ? "This attack was defeated" : "This attack was successful" }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Attack 5 IDE or ((AV) or (Solarwinds + Splunk) or (Gitlab + Nexus + Azure DevOps))</TableCell>
              <TableCell>{attackReport.attack5Success ? "This attack was defeated" : "This attack was successful" }</TableCell>
            </TableRow>
            {/* Add more rows for additional attack scenarios here */}
          </TableBody>
        </Table>
      )}


    </div>
  );


};

const storeUserState = async () => {
  try {
      const userStateJSON = formatUserState();

      // 1. Check if a matching record exists
      const response = await API.graphql(graphqlOperation(getUserState, { id: userDetails.userID }));

      // 2. If a matching record exists, update it
      if (response.data && response.data.getUserState) {
          await API.graphql(graphqlOperation(updateUserState, {
              input: {
                  id: userDetails.userID,         
                  username: userDetails.username,
                  team: userDetails.team,
                  userStateJSON: userStateJSON
              }
          }));
          //console.log("Updated existing user state.");

      // 3. Otherwise, create a new record
      } else {
          await API.graphql(graphqlOperation(createUserState, {
            input: {
              id: userDetails.userID,
              username: userDetails.username,
              team: userDetails.team,
              userStateJSON: userStateJSON
            }
          }));
          //console.log("Created new user state.");
      }

  } catch (error) {
      console.error('Error storing user state:', error);
      if (error.errors && Array.isArray(error.errors)) {
          error.errors.forEach(err => {
              //console.log('Message:', err.message);
              //console.log('Path:', err.path);
          });
      }
      throw error;
  }
};

const updateColumns = (assessedTools) => {
  const newLeftColumn = [];
  const newRightColumn = [];

  tools.forEach((tool) => {
    if (assessedTools[tool.title]) {
      if (newLeftColumn.length <= newRightColumn.length) {
        newLeftColumn.push(tool);
      } else {
        newRightColumn.push(tool);
      }
    }
  });

  setLeftColumn(newLeftColumn);
  setRightColumn(newRightColumn);
};

useEffect(() => {
  if (userDetails.isAdmin) {
    fetchAllUsers();
  }
}, [userDetails.isAdmin]);

const fetchAllUsers = async () => {
  try {
    const response = await API.graphql(graphqlOperation(listUserStates));
    const users = response.data.listUserStates.items;

    setAllUsers(users.map(user => ({
      id: user.id,
      username: user.username,
      // Map other necessary fields
    })));
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

const fetchUserState = async (userId) => {
  try {

      const userToFilterBy = userId ? userId : userDetails.userID
      //console.log("userToFilterBy", userToFilterBy)
      const response = await API.graphql(graphqlOperation(getUserState, { id: userToFilterBy }));
      //console.log("Response from getUserState:", response);
      //console.log("response fetch", response)
      if (response.data && response.data.getUserState) {
          const userState = JSON.parse(response.data.getUserState.userStateJSON);
          //console.log("Fetched showFixColumn:", userState.showFixColumn); // Add this line to log fetched showFixColumn
          setAssessedTools(userState.assessedTools || {});
          setToolNotes(userState.toolNotes || {});
          setUnassessedTools(userState.unassessedTools || {});
          setShowFixColumn(userState.showFixColumn || false);

          //console.log("columns updating", userState.assessedTools)

          updateColumns(userState.assessedTools || {});
      } 
  } catch (error) {
      console.error('Error fetching user state:', error);
      //console.log('Detailed errors:', error.errors);
  }

};



useEffect(() => {

  fetchUserState();
}, []);






  

  const navigate = useNavigate();

  // const sendSubmissionDataToLambda = async () => {
  //   try {
  //     const assessedToolDescriptions = Object.keys(assessedTools).map((toolTitle) => {
  //       const note = toolNotes[toolTitle] ? `: ${toolNotes[toolTitle]}` : '';
  //       const toolDescription = tools.find((tool) => tool.title === toolTitle)?.description || '';
  //       return toolDescription ? `${toolTitle}${note} (${toolDescription})` : `${toolTitle}${note}`;
  //     });
  
  //     const unassessedTools = tools
  //       .filter((tool) => !assessedTools[tool.title])
  //       .map((tool) => {
  //         const note = toolNotes[tool.title] ? `: ${toolNotes[tool.title]}` : '';
  //         return tool.description ? `${tool.title}${note} (${tool.description})` : `${tool.title}${note}`;
  //       });
  
  //     // Capture the totals using the helper functions
  //     const totaltime = getTotalTimeUnits();
  //     const totalmoney = getTotalMoneyUnits();

  //     //console.log("totaltime",totaltime)
  //     //console.log("totalmoney",totalmoney)
  
  //     const response = await API.graphql(
  //       graphqlOperation(checkToolSubmission, {
  //         id: 'your_submission_id',
  //         assessedTools: assessedToolDescriptions,
  //         unassessedTools,
  //         round: currentRound,
  //         totaltime: totaltime, 
  //         totalmoney: totalmoney, 
  //       })
  //     );
  
  //     //console.log('Lambda function response:', response);
  //   } catch (error) {
  //     console.error('Error calling Lambda function:', error);
  //     throw error;
  //   }
  // };
  
  const handleAssess = (tool) => {
    setAssessedTools((prev) => {
      const newState = { ...prev, [tool.title]: true };
  
      // Decide which column to place the tool in
      const updatedLeftColumn = leftColumn.length <= rightColumn.length ? [...leftColumn, tool] : leftColumn;
      const updatedRightColumn = leftColumn.length > rightColumn.length ? [...rightColumn, tool] : rightColumn;
  
      setLeftColumn(updatedLeftColumn);
      setRightColumn(updatedRightColumn);
  
      setRemainingBudget((prevBudget) => prevBudget - parseFloat(tool.cost));
      setRemainingTime((prevTime) => prevTime - parseFloat(tool.time));
  
      return newState;
    });
  };
  

  const handleRemove = (tool) => {
    setAssessedTools((prev) => {
      const newState = { ...prev };
      delete newState[tool.title];
      const updatedLeftColumn = leftColumn.filter((t) => t.title !== tool.title);
      const updatedRightColumn = rightColumn.filter((t) => t.title !== tool.title);
      setLeftColumn(updatedLeftColumn);
      setRightColumn(updatedRightColumn);
      return newState;
    });
    setRemainingBudget((prevBudget) => prevBudget + parseFloat(tool.cost));
    setRemainingTime((prevTime) => prevTime + parseFloat(tool.time));
  };

  const handleReset = () => {

    //console.log("Got here 1")
    setIsConfirmationModalOpen(false); // Close the confirmation modal
    //console.log("Got here 2")
    //console.log("resetChoice", resetChoice)
  
    if (resetChoice === 'resetAll') {
      setShowFixColumn(false);
      setAssessedTools({});
      setRemainingBudget(budget);
      setRemainingTime(timeBudget);
      setLeftColumn([]);
      setRightColumn([]);
      setToolNotes({}); // Reset notes if the choice is to reset all
    } else if (resetChoice === 'keepNotes') {
      setShowFixColumn(false);
      setAssessedTools({});
      setRemainingBudget(budget);
      setRemainingTime(timeBudget);
      setLeftColumn([]);
      setRightColumn([]);
      // Keep the notes intact
    }
  };
  

  useEffect(() => {
    if (showFixColumn) {
      storeUserState();
    }
  }, [showFixColumn, assessedTools, unassessedTools, toolNotes]);
  

  useEffect(() => {
    if (resetChoice !== null) {
      handleReset();
    }
  }, [resetChoice]);
  
  

  const handleSubmit = () => {
    // New logic to populate unassessedTools
    const newUnassessedTools = {};
    tools.forEach((tool) => {
      if (!assessedTools[tool.title]) {
        newUnassessedTools[tool.title] = true;
      }
    });
  
    setUnassessedTools(newUnassessedTools); // Update the state with unassessed tools
    setShowFixColumn(true);
  };
  
  

  const handleViewPipelineImage = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  
  const getTotalMoneyUnits = () => {
    return tools
      .filter((tool) => assessedTools[tool.title])
      .reduce((acc, tool) => acc + parseFloat(tool.cost), 0);
  };

  const getTotalTimeUnits = () => {
    return tools
      .filter((tool) => assessedTools[tool.title])
      .reduce((acc, tool) => acc + parseFloat(tool.time), 0);
  };
  
  return (  
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <AdminPanel />

  <Paper style={{ maxWidth: '75%', width: '100%', padding: '16px', marginBottom: '16px' }}>
  <ProgressBar label="Money Units" value={getTotalMoneyUnits().toFixed(2)} max={budget} />
  <ProgressBar label="Time Units" value={getTotalTimeUnits()} max={timeBudget} />

    <Typography variant="h6">Assessed Tools:</Typography>
    <Grid container>
      <Grid item xs={6}>
        <List>
          {leftColumn.map((tool) => (
            <ListItem key={tool.title}>
              <ListItemIcon>
                <DoneIcon />
              </ListItemIcon>
              <ListItemText primary={`${tool.title} - $${tool.cost}`} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={6}>
        <List>
          {rightColumn.map((tool) => (
            <ListItem key={tool.title}>
              <ListItemIcon>
                <DoneIcon />
              </ListItemIcon>
              <ListItemText primary={`${tool.title} - $${tool.cost}`} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>

  </Paper>
  <Paper style={{ maxWidth: '75%', width: '100%', padding: '16px', marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <Button variant="contained" onClick={handleViewPipelineImage}>
    View Pipeline Image
  </Button>
  <div>
    <Button variant="contained" color="secondary" onClick={openConfirmationModal} style={{ marginRight: '8px' }}>
     Reset
   </Button>

    <Button variant="contained" color="primary" onClick={handleSubmit}>
      Submit
    </Button>
  </div>
  <Modal open={isModalOpen} onClose={handleCloseModal}>
    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '75%', maxHeight: '75%' }}>
      <img src={`./images/round${currentRound}.png`} alt={`Pipeline Image Round ${currentRound}`} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
    </div>
  </Modal>
</Paper>

  <ToolTable
  tools={tools}
  onAssess={handleAssess}
  onRemove={handleRemove}
  assessedTools={assessedTools}
  showFix={showFixColumn}
  showFindings={findingsVisible}
  toolNotes={toolNotes}
  setToolNotes={setToolNotes}
/>

<Modal open={isConfirmationModalOpen} onClose={() => setIsConfirmationModalOpen(false)}>
  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '300px', backgroundColor: 'white', padding: '20px', borderRadius: '4px' }}>
    <Typography variant="h6" style={{ marginBottom: '20px' }}>Are you sure you'd like to reset all of your selections and remove all of your notes?</Typography>
    <Button variant="contained" color="primary" onClick={() => { setResetChoice('resetAll'); handleReset(); }} style={{ marginRight: '10px' }}>
      Yes, reset all
    </Button>
    <Button variant="contained" onClick={() => { setResetChoice('keepNotes'); handleReset(); }}>
      Keep notes
    </Button>
  </div>
</Modal>

</div>




    );
  }
  

export default Capstone;
