/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSubmissions = /* GraphQL */ `
  mutation CreateSubmissions(
    $input: CreateSubmissionsInput!
    $condition: ModelSubmissionsConditionInput
  ) {
    createSubmissions(input: $input, condition: $condition) {
      id
      assessedTools
      unassessedTools
      round
      user
      totaltime
      totalmoney
      team
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubmissions = /* GraphQL */ `
  mutation UpdateSubmissions(
    $input: UpdateSubmissionsInput!
    $condition: ModelSubmissionsConditionInput
  ) {
    updateSubmissions(input: $input, condition: $condition) {
      id
      assessedTools
      unassessedTools
      round
      user
      totaltime
      totalmoney
      team
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubmissions = /* GraphQL */ `
  mutation DeleteSubmissions(
    $input: DeleteSubmissionsInput!
    $condition: ModelSubmissionsConditionInput
  ) {
    deleteSubmissions(input: $input, condition: $condition) {
      id
      assessedTools
      unassessedTools
      round
      user
      totaltime
      totalmoney
      team
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAuthoritativeAnswer = /* GraphQL */ `
  mutation CreateAuthoritativeAnswer(
    $input: CreateAuthoritativeAnswerInput!
    $condition: ModelAuthoritativeAnswerConditionInput
  ) {
    createAuthoritativeAnswer(input: $input, condition: $condition) {
      id
      team
      lab
      username
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAuthoritativeAnswer = /* GraphQL */ `
  mutation UpdateAuthoritativeAnswer(
    $input: UpdateAuthoritativeAnswerInput!
    $condition: ModelAuthoritativeAnswerConditionInput
  ) {
    updateAuthoritativeAnswer(input: $input, condition: $condition) {
      id
      team
      lab
      username
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAuthoritativeAnswer = /* GraphQL */ `
  mutation DeleteAuthoritativeAnswer(
    $input: DeleteAuthoritativeAnswerInput!
    $condition: ModelAuthoritativeAnswerConditionInput
  ) {
    deleteAuthoritativeAnswer(input: $input, condition: $condition) {
      id
      team
      lab
      username
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLab1 = /* GraphQL */ `
  mutation CreateLab1(
    $input: CreateLab1Input!
    $condition: ModelLab1ConditionInput
  ) {
    createLab1(input: $input, condition: $condition) {
      id
      phase
      ToolOrActivity
      sortIndex
      isTools
      required
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLab1 = /* GraphQL */ `
  mutation UpdateLab1(
    $input: UpdateLab1Input!
    $condition: ModelLab1ConditionInput
  ) {
    updateLab1(input: $input, condition: $condition) {
      id
      phase
      ToolOrActivity
      sortIndex
      isTools
      required
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLab1 = /* GraphQL */ `
  mutation DeleteLab1(
    $input: DeleteLab1Input!
    $condition: ModelLab1ConditionInput
  ) {
    deleteLab1(input: $input, condition: $condition) {
      id
      phase
      ToolOrActivity
      sortIndex
      isTools
      required
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLab1Answers = /* GraphQL */ `
  mutation CreateLab1Answers(
    $input: CreateLab1AnswersInput!
    $condition: ModelLab1AnswersConditionInput
  ) {
    createLab1Answers(input: $input, condition: $condition) {
      id
      lab1id
      phase
      team
      ToolOrActivity
      isSelected
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLab1Answers = /* GraphQL */ `
  mutation UpdateLab1Answers(
    $input: UpdateLab1AnswersInput!
    $condition: ModelLab1AnswersConditionInput
  ) {
    updateLab1Answers(input: $input, condition: $condition) {
      id
      lab1id
      phase
      team
      ToolOrActivity
      isSelected
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLab1Answers = /* GraphQL */ `
  mutation DeleteLab1Answers(
    $input: DeleteLab1AnswersInput!
    $condition: ModelLab1AnswersConditionInput
  ) {
    deleteLab1Answers(input: $input, condition: $condition) {
      id
      lab1id
      phase
      team
      ToolOrActivity
      isSelected
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserState = /* GraphQL */ `
  mutation CreateUserState(
    $input: CreateUserStateInput!
    $condition: ModelUserStateConditionInput
  ) {
    createUserState(input: $input, condition: $condition) {
      id
      username
      team
      userStateJSON
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const updateUserState = /* GraphQL */ `
  mutation UpdateUserState(
    $input: UpdateUserStateInput!
    $condition: ModelUserStateConditionInput
  ) {
    updateUserState(input: $input, condition: $condition) {
      id
      username
      team
      userStateJSON
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const deleteUserState = /* GraphQL */ `
  mutation DeleteUserState(
    $input: DeleteUserStateInput!
    $condition: ModelUserStateConditionInput
  ) {
    deleteUserState(input: $input, condition: $condition) {
      id
      username
      team
      userStateJSON
      createdAt
      updatedAt
      userId
      __typename
    }
  }
`;
export const createRound = /* GraphQL */ `
  mutation CreateRound(
    $input: CreateRoundInput!
    $condition: ModelRoundConditionInput
  ) {
    createRound(input: $input, condition: $condition) {
      id
      name
      report
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRound = /* GraphQL */ `
  mutation UpdateRound(
    $input: UpdateRoundInput!
    $condition: ModelRoundConditionInput
  ) {
    updateRound(input: $input, condition: $condition) {
      id
      name
      report
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRound = /* GraphQL */ `
  mutation DeleteRound(
    $input: DeleteRoundInput!
    $condition: ModelRoundConditionInput
  ) {
    deleteRound(input: $input, condition: $condition) {
      id
      name
      report
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGameState = /* GraphQL */ `
  mutation CreateGameState(
    $input: CreateGameStateInput!
    $condition: ModelGameStateConditionInput
  ) {
    createGameState(input: $input, condition: $condition) {
      id
      round
      phase
      budget
      timeBudget
      capstoneActive
      lab1Active
      findingsVisible
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGameState = /* GraphQL */ `
  mutation UpdateGameState(
    $input: UpdateGameStateInput!
    $condition: ModelGameStateConditionInput
  ) {
    updateGameState(input: $input, condition: $condition) {
      id
      round
      phase
      budget
      timeBudget
      capstoneActive
      lab1Active
      findingsVisible
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGameState = /* GraphQL */ `
  mutation DeleteGameState(
    $input: DeleteGameStateInput!
    $condition: ModelGameStateConditionInput
  ) {
    deleteGameState(input: $input, condition: $condition) {
      id
      round
      phase
      budget
      timeBudget
      capstoneActive
      lab1Active
      findingsVisible
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTools = /* GraphQL */ `
  mutation CreateTools(
    $input: CreateToolsInput!
    $condition: ModelToolsConditionInput
  ) {
    createTools(input: $input, condition: $condition) {
      id
      title
      cost
      round
      finding
      time
      fix
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTools = /* GraphQL */ `
  mutation UpdateTools(
    $input: UpdateToolsInput!
    $condition: ModelToolsConditionInput
  ) {
    updateTools(input: $input, condition: $condition) {
      id
      title
      cost
      round
      finding
      time
      fix
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTools = /* GraphQL */ `
  mutation DeleteTools(
    $input: DeleteToolsInput!
    $condition: ModelToolsConditionInput
  ) {
    deleteTools(input: $input, condition: $condition) {
      id
      title
      cost
      round
      finding
      time
      fix
      image
      createdAt
      updatedAt
      __typename
    }
  }
`;
