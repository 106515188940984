import React, { createContext, useContext, useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listGameStates } from '../graphql/queries';
import { onUpdateGameState } from '../graphql/subscriptions';
import { listTools } from '../graphql/queries';

const GameContext = createContext();

export const useGameContext = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
  const [budget, setBudget] = useState(0);
  const [timeBudget, setTimeBudget] = useState(0);
  const [remainingBudget, setRemainingBudget] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [currentRound, setCurrentRound] = useState(null);
  const [currentPhase, setCurrentPhase] = useState(null);
  const [gameStates, setGameStates] = useState([]);
  const [tools, setTools] = useState([]);
  const [capstoneActive, setCapstoneActive] = useState(false);
  const [lab1Active, setLab1Active] = useState(false);
  const [findingsVisible, setFindingsVisible] = useState(false);

  async function fetchTools() {
    try {
      const toolData = await API.graphql(graphqlOperation(listTools));
      const toolsList = toolData.data.listTools.items;
  
      const filteredTools = toolsList.filter((tool) => tool.round === currentRound); 
      setTools(filteredTools); 
  
    } catch (error) {
      console.error("Error fetching tools:", error.message);
      console.error("Error stack:", error.stack);
  
      if (error.errors && Array.isArray(error.errors)) {
        error.errors.forEach((err, index) => {
          console.error(`Detail error ${index + 1}:`, err.message);
          if (err.locations) {
            console.error(`Locations for error ${index + 1}:`, err.locations);
          }
          if (err.path) {
            console.error(`Path for error ${index + 1}:`, err.path);
          }
        });
      }
    }
  }

  useEffect(() => {
    const fetchGameState = async () => {
      try {
        const response = await API.graphql(graphqlOperation(listGameStates));
        const gameStateItems = response.data.listGameStates.items;

        if (gameStateItems.length > 0) {
          const gameState = gameStateItems[0];
          setCurrentRound(gameState.round);
          setCurrentPhase(gameState.phase);
          setBudget(gameState.budget);
          setTimeBudget(gameState.timeBudget);
          setRemainingBudget(gameState.budget);
          setRemainingTime(gameState.timeBudget);
          setLab1Active(gameState.lab1Active)
          setCapstoneActive(gameState.capstoneActive);
          setFindingsVisible(gameState.findingsVisible);
        }
      } catch (error) {
        console.error('Error fetching game state:', error);
      }
    };

    fetchGameState();

      if (currentRound) {
        fetchTools();
    }

    const subscription = API.graphql(graphqlOperation(onUpdateGameState)).subscribe({
      next: ({ provider, value }) => {
        const gameState = value.data.onUpdateGameState;
        setCurrentRound(gameState.round);
        setCurrentPhase(gameState.phase);
      },
      error: (error) => {
        console.warn('Subscription error: ', error);
      },
    });

    return () => subscription.unsubscribe();
  }, [currentRound]);

  const values = {
    budget,
    setBudget,
    timeBudget,
    setTimeBudget,
    remainingBudget,
    setRemainingBudget,
    remainingTime,
    setRemainingTime,
    currentRound,
    setCurrentRound,
    currentPhase,
    setCurrentPhase,
    gameStates,
    setGameStates,    
    tools, 
    setTools,
    capstoneActive, 
    setCapstoneActive, 
    findingsVisible, 
    setFindingsVisible,
    lab1Active, 
    setLab1Active,
  };

  return <GameContext.Provider value={values}>{children}</GameContext.Provider>;
};

