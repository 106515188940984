import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useUserContext } from '../UserContext';

const ProtectedRoute = ({ element }) => {
  const { userDetails } = useUserContext();

  //console.log("usereDetails.isAdmin", userDetails.isAdmin)
  
  return userDetails.isAdmin ? element : <Navigate to="/" replace />;
};

export default ProtectedRoute;
